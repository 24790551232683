@charset "UTF-8";
.files_box[data-v-7013d069] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 5px;
}
.files_box .files_box_span[data-v-7013d069] {
  margin: 0 10px;
}
.title_suffix[data-v-7013d069] {
  background-color: #C5211B;
  padding: 2px 5px;
  color: #fff;
  margin-left: 5px;
}
.custom-dropdown .el-scrollbar__view[data-v-7013d069] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-dropdown .el-select-dropdown__item[data-v-7013d069] {
  max-width: 500px; /* 设置下拉项的宽度 */
  overflow: hidden;
  text-overflow: ellipsis;
}
[data-v-7013d069] .el-divider__text, .el-link[data-v-7013d069] {
  font-size: 14px !important;
}
[data-v-7013d069] .el-upload-list__item.is-success.focusing .el-icon-close-tip {
  display: none !important;
}